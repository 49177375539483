<template>
  <div>
    <div id="nav" class="relative z-30">
      <div class="w-screen overflow-hidden relative z-10 px-8">
        <div class="max-w-screen-xl mx-auto  relative z-20 pt-8 relative flex justify-between items-center">
          <div class="relative mb-0">
            <slot name="brand"/>
          </div>
          <div class="h-8 flex items-center gap-x-6">
            <a href="/contact" class="text-white hidden sm:inline-block border-secondary border px-3 py-2 rounded-full mr-4 hover:bg-secondary transition-all ease-in-out duration-300">Contact us</a>
            <button name="main menu" aria-label="main menu" type="button" @click="addo();addb();addh();"
                    class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class=" z-10 heighter px-8">
      <div class="container relative">
        <div class="lg:pt-56 py-32 sm:pt-48 sm:pb-32 lg:pb-24 flex w-full justify-center lg:justify-start items-center">
          <div>
            <slot name="mainmenu"/>
          </div>
        </div>
        <div class="hidden lg:flex absolute bottom-8 right-0 pt-10 lg:pt-6 pb-6 px-8 justify-end items-end">
          <div class="lg:w-1/2">
            <div class="my-4 lg:my-0 flex gap-x-4 justify-start lg:justify-end items-center">
              <a href="https://www.linkedin.com/company/b4b-renewables-ltd/" target="_blank" class="text-white hover:text-secondary transition-all ease-in-out duration-300">
                <icon-linkedin></icon-linkedin>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isOpenLeft: false,
      services: false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo() {
      document.querySelector('.heighter').classList.toggle('heights')
    },
    addb() {
      document.querySelector('.brand').classList.toggle('white-b')
    },
    addh () {
      document.querySelector('.page').classList.toggle('max-vis')
    },
    addc() {
      document.querySelector('.contat-header').classList.toggle('white-c')
    },
  }
}
</script>
